.tire-result-white-line{
    height: 1px;
    width:100%;
    background-color: white;
    margin-top: 8px;
    margin-bottom: 8px;
}
.tire-result-popup-detail-content{
    grid-template-columns: 50px 170px 50px 50px;
    color: white;
    width: 100%;
    text-align: left;
}

.tire-result-popup{
    position:static;  
    width: 350px;
    height: 270px;
    z-index: 1000;    
}

.tire-result-popup-triangle {
    width: 40px;
    height: 40px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #242424;
    position: absolute;
    top: -40px;
    left: 150px;
    background-color: transparent;
    transform: rotate(90deg);
  }
  
  .tire-result-popup-box {
    position: relative;  
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    padding: 13px 13px 13px 13px;    
    border: 1px solid white;
    background-color: #242424;
    border-radius: 5px;
  }