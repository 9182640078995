.complex-sub-menu-item-font {
        text-transform: uppercase;
        display: block;
        color: #8b8b8b;
        font-size: 0.6875em;
        font-weight: 900;
        transition: color 0.15s ease-in-out;
        font-family: "Lato", sans-serif;
}

.link-list .link-list-item div {
        display: block;
        color: #8b8b8b;
        font-size: 0.6875em;
        font-weight: 900;
        transition: color 0.15s ease-in-out;
}

.link-list .link-list-item:hover div {
        color: #fff;
}

.link-list.simple .link-list-item div {
        text-transform: uppercase;
}

.link-list.decorated .link-list-item div {
        padding-left: 18px;
        font-size: 0.875em;
        font-weight: 400;
        position: relative;
}

.link-list.decorated .link-list-item div .svg-arrow {
        fill: #ff5400;
        pointer-events: none;
        position: absolute;
        top: 4px;
        left: 0;
}