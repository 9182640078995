@media screen and (max-width: 1000px) {
    #mobile-menu-main-div {
        display: block;
    }
}

@keyframes mobile-menu-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.mobile-menu-menuParent~label>div>div>svg {
    transition: all ease-in-out .25s;
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;

}

.mobile-menu-menuParent:checked~label>div>div>svg {
    transform: rotate(90deg);
}

.mobile-menu-menuParent:checked~.mobile-menu-menuLeaf {
    display: block;
    animation: mobile-menu-fadeIn 2s;
}

.mobile-menu-top-element {
    background-color: #242424;
    color: white;
    text-align: center;
}

.mobile-menu-grid-2-columns {
    grid-template-columns: 90% 50px
}



.mobile-menu-line_second_column {
    text-align: right;
    padding-right: 20px;
    width: 50px;
}



.mobile-menu-accormenu-checkbox {
    font-size: 0.9em !important;
    font-weight: 900;
    font-family: "Lato", sans-serif;
    text-decoration: none;
    text-transform: none !important;
    letter-spacing: 0px !important;
    REMOVEpadding-left: 30px;
    height: 30px;
    color: white;
}

.mobile-menu-not-selected {
    border-top: 1px solid #3E3E3E;
    background-color: #242424;
    color: white;
    text-align: left;
}

.mobile-menu-subMenuItem_WithChild {
    background-color: #242424;
    border-top: 1px solid #3E3E3E;
    color: white;
    min-height: 30px;
    line-height: 30px;
    padding-top: 10px;
}

.mobile-menu-subMenuItem {
    background-color: #1B1B1B;
    color: white;
    min-height: 30px;
    line-height: 30px;
    padding-top: 10px;
}

.mobile-menu-submenu {
    display: none;
    color: white !important;
}

.mobile-menu-burger {
    width: 100px;
    background-color: #242424;
    height: 50px !important;
    min-height: 50px !important;
    max-height: 50px !important;
    line-height: 50px;
}

.mobile-menu-so-accordion-wrapper-padding-top {
    padding-top: 0px;
}

.mobile-menu-so-accordion-wrapper {
    padding-top: 0rem;
    overflow: visible;
    border-bottom: 1px solid #eaeaea;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 820px;
}

.mobile-menu-so-tab {
    position: relative;
    width: 100%;
    border-top: 1px solid #eaeaea;
    overflow: hidden;
    padding-left: 0px;
}

.mobile-menu-so-tab-no-padding-top {
    border-top: 0px !important;
}

.mobile-menu-so-tab label {
    position: relative;
    display: block;
    padding: 0 0 0 0;
    line-height: normal;
    cursor: pointer;
    letter-spacing: 0.2em;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
}

.mobile-menu-so-tab input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.mobile-menu-so-tab-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 2.0s cubic-bezier(0, 1, 0, 1);
}

/* :checked */
.mobile-menu-so-tab input:checked~.mobile-menu-so-tab-content {
    max-height: 1000px;
    transition: max-height 2.0s ease-in-out;
}