.compare-page-dim-result-grid-16-columns{
    grid-template-columns: 100px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px 30px;
    height: 18px;
    line-height: 18px;
}

.compare-page-rl-label{
    display: block;
    margin-bottom: 12px;
    color: #242424;
    font-size: 0.9375em;
    font-weight: 700;
    font-family: "Lato", sans-serif;
}

.compare-page-calc-button{
    width: 140px;
    height: 30px;
    line-height: 30px;
}

.compare-page-font-small{
    font-size: 0.75em !important; 
}

.compare-page-font-small2{
    font-size: 0.75em !important;
    font-weight: 100 !important;
}

.compare-page-gray-line{
    background-color: #E8E8E8;
    height: 1px;
    min-height: 1px;
    width: 630px;    
}

.compare-page-forcetop{
    top: 12px !important;
    }
    
.compare-page-column-DimTire3{
    grid-template-columns: 190px 90px 50px 90px 50px 150px;
    height: 18px;
    line-height: 18px;
}

.compare-page-column-DimTire2{
    grid-template-columns: 90px 15px 90px 15px 90px 200px;
}

.compare-page-column-DimTire{
    grid-template-columns: 90px 15px 90px 15px 90px;
}