.slider-filter-button {
    width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 0.625em;
    display: table;
    margin: 0 auto;
}

.slider-filter-line-separator {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.slider-filter-title {
    font-size: 1.1em !important;
    text-transform: none !important;
    font-weight: 900 !important;
    letter-spacing: 0px !important;
    color: black;
    margin-bottom: 0px !important;
}

.slider-filter-range-slider {
    height: 5px;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E8E8E8;
}

.slider-filter-range-selected {
    height: 100%;
    left: 30%;
    right: 30%;
    position: absolute;
    border-radius: 5px;
    background-color: #E8E8E8;
}

.slider-filter-range-input {
    position: relative;
}

.slider-filter-range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.slider-filter-range-input input::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 5px solid #242424;
    background-color: #fff;
    pointer-events: auto;
    -webkit-appearance: none;
}

.slider-filter-range-input input::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 3px solid #1b53c0;
    background-color: #fff;
    pointer-events: auto;
    -moz-appearance: none;
}

.slider-filter-result-output{
    width: 100%;
    text-align: center;
}