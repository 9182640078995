.tire-result-page-grid-3-colums{
    grid-template-columns: 200px 20px 100%;    
}

.tire-result-page-no-padding-top{
    padding-top: 0px !important;
}

.tire-result-page-no-result{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}