.tire-result-sort-grid-5-columns {
    grid-template-columns: 445px 75px 250px 75px 75px;    
    height: 70px;    
}

.tire-result-sort-grid-sizes-right {
    text-align: right;
    padding-top: 25px;
}

.tire-result-sort-grid-sizes-left {
    text-align: left;
    padding-top: 25px;
    padding-left: 20px;
}

.tire-result-sort-font {
    font-size: 12px;
    font-weight: 900;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
}

.tire-result-sort-combo-height{
    line-height: 45px !important;
    height: 45px !important;
}

.tire-result-sort-combo-arrow {
    top: 18px !important;
}

.tire-result-sort-button-selected{    
    color: white;
    background-color: #FF7200 !important;
}

.tire-result-sort-button-dollar{    
    padding-top: 7px !important;
    padding-left: 14px !important;
    border-radius: 5px;
    background: white;        
    width: 45px;
    height: 45px;
    border: 1px solid #E8E8E8;  
}

.tire-result-sort-button{    
        padding-top: 5px !important;
        padding-left: 7px !important;
        border-radius: 5px;
        background: white;        
        width: 45px;
        height: 45px;
        border: 1px solid #E8E8E8;  
}


.tire-result-sort-popup-parent{
    position: relative;
    }
    
    .tire-result-sort-popup-child{
    position: absolute;
    z-index: 1999;
    }