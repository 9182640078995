  .userlist-inline {
    display: inline-block !important;
  }

  .userlist-no-user {
    text-align: center !important;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1em;
    text-transform: uppercase;
    font-family: "Lato, sans-serif";
    font-weight: 900;
  }

  .userlist-centerize {
    text-align: center !important;
    margin-top: 20px;
  }

  .userlist-button-middle {
    display: inline-block !important;
  }

  .userlist-vertical-center{
    line-height: 38px !important;
  }

  .userlist-gray_color{
    color: #E8E8E8;
  }

  .userlist-table .userlist-table-row:after,
  .userlist-table .userlist-table-header:after {
      content: '';
      display: table;
      clear: both;
  }

  .userlist-table .userlist-table-row>*,
  .userlist-table .userlist-table-header>* {
      float: left;
      height: 100%;
  }

  .userlist-table .userlist-table-header {
      height: 50px;
      border-bottom: 1px solid #e8e8e8;
  }

  .userlist-table .userlist-table-header .title {
      font-size: 0.75em;
      line-height: 50px;
  }

  .userlist-table-row-gray{
    background-color: #F9F9F9;
  }

  .userlist-table-row-white{
    background-color: white;
  }

  .userlist-table .userlist-table-row {
      height: 40px;
      padding-right: 35px;
      border-bottom: 1px solid #e8e8e8;
      position: relative;
  }

  .userlist-table .userlist-table-row .userlist-table-photo {
      padding-top: 24px;
  }

  .userlist-table .userlist-table-row .userlist-table-photo .product-img {
      display: inline-block;
  }

  .userlist-table .userlist-table-row .userlist-table-name .userlist-table-user .userlist-table-type .userlist-table-email .userlist-table-password {
      padding-top: 40px;
  }

  .userlist-table .userlist-table-row .userlist-table-name .userlist-table-user .userlist-table-type .userlist-table-email .userlist-table-password .title {
      display: inline-block;
  }

  .userlist-table .userlist-table-row .userlist-table-name .userlist-table-user .userlist-table-type .userlist-table-email .userlist-table-password .product-description {
      margin-bottom: 4px;
  }

  .userlist-table .userlist-table-row .userlist-table-description {
      padding-top: 36px;
  }

  .userlist-table .userlist-table-row .userlist-table-description p {
      width: 270px;
  }

  .userlist-table .userlist-table-row .userlist-table-price,
  .userlist-table .userlist-table-row .userlist-table-total {
      padding-top: 56px;
  }

  .userlist-table .userlist-table-row .userlist-table-quantity {
      padding-top: 40px;
  }

  .userlist-table .userlist-table-row .userlist-table-quantity.numerical {
      padding-top: 56px;
  }

  .userlist-table .userlist-table-row .userlist-table-quantity .quantity-input {
      text-align: left;
      margin: 0 auto;
  }

  .userlist-table .userlist-table-row .userlist-table-availability {
      padding-top: 58px;
  }

  .userlist-table .userlist-table-row .userlist-table-availability .title.void {
      color: #b2b2b2;
  }

  .userlist-table .userlist-table-row .userlist-table-actions {
      padding: 48px 0 0 25px;
  }

  .userlist-table .userlist-table-row .remove-fcart {
      position: absolute;
      top: 58px;
      right: 34px;
  }

  .userlist-table .userlist-table-row .remove-fcart .svg-cross {
      fill: #b2b2b2;
      cursor: pointer;
  }

  .userlist-table .userlist-table-photo {
      width: 150px;
  }

  .userlist-table .userlist-table-name {
      width: 250px;
  }

  .userlist-table .userlist-table-user {
      width: 150px;
  }

  .userlist-table .userlist-table-type {
      width: 150px;
  }

  .userlist-table .userlist-table-email {
      width: 250px;
  }


  .userlist-table .userlist-table-password {
      width: 150px;
  }

  .userlist-table .userlist-table-description {
      width: 315px;
  }

  .userlist-table .userlist-table-description.long {
      width: 475px;
  }

  .userlist-table .userlist-table-price {
      width: 104px;
  }

  .userlist-table .userlist-table-quantity,
  .userlist-table .userlist-table-availability {
      width: 190px;
  }

  .userlist-table .userlist-table-quantity .title .times {
      display: none;
  }

  .userlist-table .userlist-table-total {
      width: 90px;
  }

  .userlist-table .userlist-table-quantity,
  .userlist-table .userlist-table-availability,
  .userlist-table .userlist-table-price,
  .userlist-table .userlist-table-total {
      text-align: center;
  }



  @media screen and (max-width: 1110px) {
      .userlist-table {
          width: 570px;
          margin-top: 50px;
      }

      .userlist-table:after {
          content: '';
          display: table;
          clear: both;
      }

      .userlist-table.v2 .userlist-table-header,
      .userlist-table.v2 .userlist-table-row {
          padding-left: 0;
      }

      .userlist-table.v2 .userlist-table-row {
          margin-bottom: 10px;
      }

      .userlist-table.v2 .userlist-table-row:last-child {
          margin-bottom: 60px;
      }

      .userlist-table.v2 .userlist-table-quantity.numerical {
          padding: 20px 0 0 100px;
      }

      .userlist-table.v2 .userlist-table-quantity.numerical .title .times {
          display: inline;
      }

      .userlist-table.v2 .userlist-table-quantity.numerical+.userlist-table-total {
          padding: 20px 100px 0 0;
          margin-top: 0;
      }

      .userlist-table .userlist-table-header {
          display: none;
      }

      .userlist-table .userlist-table-row {
          float: left;
          width: 270px;
          height: auto;
          margin: 0 30px 30px 0;
          padding-right: 0;
          padding-bottom: 30px;
          text-align: center;
      }

      .userlist-table .userlist-table-row:nth-child(odd) {
          margin-right: 0;
      }

      .userlist-table .userlist-table-row:last-child {
          margin-bottom: 60px;
      }

      .userlist-table .userlist-table-row>* {
          float: none;
          width: 270px;
          height: auto;
      }

      .userlist-table .userlist-table-row .userlist-table-name,
      .userlist-table .userlist-table-row .userlist-table-user,
      .userlist-table .userlist-table-row .userlist-table-type,
      .userlist-table .userlist-table-row .userlist-table-email,
      .userlist-table .userlist-table-row .userlist-table-price,
      .userlist-table .userlist-table-row .userlist-table-password,
      .userlist-table .userlist-table-row .userlist-table-quantity,
      .userlist-table .userlist-table-row .userlist-table-total,
      .userlist-table .userlist-table-row .userlist-table-availability,
      .userlist-table .userlist-table-row .userlist-table-actions {
          padding: 0px 0 0;
      }

      .userlist-table .userlist-table-row .userlist-table-photo {
          padding-top: 0;
      }

      .userlist-table .userlist-table-row .button,
      .userlist-table .userlist-table-row .rating {
          display: inline-block;
      }

      .userlist-table .userlist-table-row .remove-fcart {
          width: auto;
          top: 5px;
      }

      .userlist-table .userlist-table-row .userlist-table-quantity {
          float: left;
          width: auto;
          padding-left: 70px;
      }

      .userlist-table .userlist-table-row .userlist-table-total {
          float: right;
          width: auto;
          margin-top: 16px;
          padding-right: 70px;
      }
  }

  @media screen and (max-width: 860px) {
      .section-message p {
          width: 95%;
      }
  }

  @media screen and (max-width: 680px) {
      .userlist-table {
          width: 270px;
      }

      .userlist-table .userlist-table-row {
          margin-right: 0;
      }
  }