.menuItemFirstLevelJS{
    display: block;
    padding: 0 30px;
    color: #242424;
    font-size: 0.75em;
    line-height: 90px;
    font-weight: 900;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out;
    position: relative;
    font-family: "Lato", sans-serif;
}

.menuItemFirstLevelJS:hover{
    color: #FF5400;
}

.menuItemFirstLevelJSNoPadLeft{
display: block;
padding-right: 30px;
padding-left: 0px;
color: #242424;
font-size: 0.75em;
line-height: 90px;
font-weight: 900;
text-transform: uppercase;
transition: color 0.15s ease-in-out;
position: relative;
font-family: "Lato", sans-serif;
}