.tire-result-main-grid-2-columns {
  grid-template-columns: 950px;
  background-color: white;
  width: 950px;
  max-width: 950px;
}

.tire-result-grid-sizes-no-price {
  grid-template-columns: 470px 120px 120px 120px 114px;
  background-color: white;
}

.tire-result-grid-sizes {
  grid-template-columns: 470px 120px 120px 120px 114px;
  background-color: white;
}