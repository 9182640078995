.partner-grid-2-columns {
    grid-template-columns: 30px 150px;        
}

.partner-bg {
    background-color: #F1F1F1 !important;    
    padding-bottom: 5px;
}

.partner-grid-item-image-element {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
    padding-left: 10px;
    width: 100%;
    text-align: left;
}

.partner-label-font-bold {
    font-size: 15px;
    font-weight: 700;
    color: #242424;
    font-family: "Lato", sans-serif;
}