.result-pagination-grid-3-colons {
    grid-template-columns: 50px 100px 50px;

}

.result-pagination-margin {
    align-items: center;
    justify-items: center;
    text-align: center;
    margin: auto;
}

.result-pagination-div {
    width: 200px;
    margin-top: 40px;
}

.result-pagination-box-next {
   margin-left: 8px !important;
}

.result-pagination-box-previous {
    margin-right: 8px !important;
}

.result-pagination-box {
    display: block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    color: #242424;
    font-size: 0.8125em;
    font-weight: 700;
    text-align: center;
    transition: color 0.15s ease-in-out;
}

.pager .pager-item.selected span {
    color: #ff5400;
}

.result-pagination-combo-box {
    height: 42px !important;
    line-height: 42px !important;
    width: 80px;
}

.result-pagination-box-arrow {
    fill: #242424;
    transition: fill 0.15s ease-in-out;
}