.tire-result-desc-discount-tag-triangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #FF5400;
  position: absolute;
  top: 10px;
  left: 0px;
}

.tire-result-desc-discount-tag {
  position: relative;  
  width: 75px;
  height: 20px;
  left: -13px;
  top: 3px;
  padding-top: 3px;
  padding-left: 15px;
}

.tire-result-desc-grid-column2{
  height: 100%;
  padding-bottom: 10px;
}

.tire-result-desc-grid-2-colons {
  grid-template-columns: 150px 320px;
  height: 100%;
}

.tire-result-desc-grid-2-colons22 {
  grid-template-columns: 160px 160px;
}

.tire-result-desc-type-grid-2-colons {
  grid-template-columns: 30px 152px;
}

.tire-result-desc-button-spec {
  width: 90px;
  height: 25px;
  line-height: 21px;
  font-size: 0.625em;
}
