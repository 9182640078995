.partners-div{
    border-left: 2px solid #FF5500;
    padding-left: 10px;
    padding-right: 20px;
}

.partners-grid-1-column {
    grid-template-columns: 100%;    
}

.partners-line {
    background-color: #F1F1F1;
    padding-bottom: 20px;
    text-align: center;
    font-size: 0.9375em;
    font-weight: 700;
    color: #242424;
    font-family: "Lato", sans-serif;
}
