.info-popup-white-line{
    height: 1px;
    width:100%;
    background-color: white;
    margin-top: 8px;
    margin-bottom: 8px;
}
.info-popup-popup-detail-content{
    grid-template-columns: auto;
    color: white;
    width: 100%;
    text-align: center;
}

.info-popup-popup{
    position:static;  
    width: 130px;
    height: 45px;
    z-index: 1000;    
    text-align: center;
}

.info-popup-popup-triangle {
    width: 20px;
    height: 20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #242424;
    position: absolute;
    top:40px;
    left: 55px;
    background-color: transparent;
    transform: rotate(270deg);
  }
  
  .info-popup-popup-box {
    position: relative;  
    width: 100%;
    height: 100%;
    top: -90px;
    padding: 10px 10px 10px 10px;    
    border: 1px solid white;
    background-color: #242424;
    border-radius: 5px;
    z-index: 2000;
  }

