.converter-column {
    grid-template-columns: 772px;
}

.converter-tab-row {
    grid-template-columns: 280px 330px 120px;
}

.converter-gray-line{
    background-color: #E8E8E8;
    height: 1px;
    min-height: 1px;
    width: 630px;    
}

.converter-top-right{
    text-align: right;
    vertical-align: top;
}

.converter-width-330{
    width: 330px;
}

.converter-base-main-div {
    width: 100%;
    height: 100%;
    background-color: black;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
}

.converter-base-submain-div {
    width: 100%;
    height: 100%;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

