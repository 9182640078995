.tire-result-headers-sticky{
  position: -webkit-sticky;
  position:sticky;
  top: 0px; 
  z-index: 10000;   
  border-bottom: 1px solid white;
  }

.tire-result-headers {   
  padding-top: 7px; 
    margin-left: 0px;
    background-color: black !important;
    color: white;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 950px;
  }