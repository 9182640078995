.oldsite-full-Iframe {
    display: block;
    height: 100vh;
    width: 100vw;
    border: none;
    background: white;
}

.oldsite-width-large {
    width: 300px !important;
}

.oldsite-maximize-line {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.oldsite-back-button {
    display: block;
    text-align: center;
    line-height: 50px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    font-family: "Lato", sans-serif;
    text-decoration: none;
}

.actions-list .actions-list-item .social-link div {
    display: block;
    text-align: center;
    line-height: 50px;
    color: #fff;
    transition: all 0.3s ease-in-out; }
    .actions-list .actions-list-item .social-link div:hover {
      background-color: #ff5400; }