.tiresearch-grid-3-columns {
    grid-template-columns: 650px 30px 650px;
}

.tiresearch-grid-1-column {
    grid-template-columns: 100%;
}

.tiresearch-no-padding-bottom {
    padding-bottom: 0px !important;
}

.tiresearch-grid-1-column_2 {
    grid-template-columns: 650px;
}

.tiresearch-padding-section {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
}

.tiresearch-grid-item-search-body {
    background-color: #F1F1F1;
    padding-top: 30px;
}

.tiresearch-grid-item-centerized {
    display: flex;
    padding: 0px;
    align-content: flex-start;
}

.tiresearch-gridSelected {
    background-color: #F1F1F1;
    font-size: 0.9em;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    color: black;
}

.tiresearch-gridNotSelected {
    background-color: #242424 !important;
    font-size: 0.9em;
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    color: white;
}

.tiresearch-grid-2-columns {
    grid-template-columns: 50px 186px;
}

.tiresearch-grid-2-columns_2 {
    grid-template-columns: 50px 146px;
}

.tiresearch-grid-2-columns_3 {
    grid-template-columns: 50px 106px;
}

.tiresearch-grid-4-columns {
    grid-template-columns: 240px 200px 160px auto;
}

.tiresearch-grid-item-selected {
    background-color: #F1F1F1;
    border-top: 2px solid #FF5500;
    padding: 0px;
    margin-right: 4px;
    text-align: center;
}

.tiresearch-grid-item-not-selected {
    background-color: black;
    border-top: 2px solid white;
    padding: 0px;
    margin-right: 4px;
    text-align: center;
}

.tiresearch-grid-item-image-element {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 4px;
    padding-left: 10px;
    width: 100%;
    text-align: left;
}

.tiresearch-button-edit {
    width: 170px;
    height: 40px;
    background-color: #242424;
    line-height: 40px;
    font-size: 0.625em;
}


@media screen and (max-width: 1220px) {

    .tiresearch-grid-3-columns{   
        grid-template-columns: 650px !important;
    }

    .tiresearch-spacer{
        display: none;
    }
}
