.joinus-contact-form {
  float: left !important;
  width: 500px;
  min-height: 608px;
  
}

.joinus-map-div{
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.joinus-contact-form-map{  
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  padding-top: 0px !important; 
}


.joinsus-grid{
  grid-template-columns: 50% 50%;
}

.joinus-bottom{
  max-width: 100% !important;
  width: 100% !important;
}

.joinus-contact-div-main{
  display: flex;  
  margin-right: 80px;
  margin-left: 80px;
}

.joinus-contact-div{
  width: 100% !important;
}

@media screen and (max-width: 1110px) {
  .joinsus-grid{
    grid-template-columns: 100%;
  }

  .joinus-contact-div{
    width: 90% !important;
  }

  .joinus-contact-div-main{
    justify-content: center;    
    margin-right: 0px;
    margin-left: 0px;
  }
}

