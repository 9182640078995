.S1-topmenu-phone-img {
    width: 20px;
    vertical-align: middle;
    padding-top: 14px;
}

.S1-topmenu-vertcenter {
    height: 100%;
    vertical-align: middle;
}

.S1-topmenu-topMenuColor {
    background-color: #242424 !important;
}

.S1-topmenu-noborder {
    border-right: 0px !important;
    border-left: 0px !important;
}