.tire-result-discount-popup-hide {
    display: none;
}
.tire-result-discount-popup-show {
    display: block;
}

.tire-result-discount-popup{
    position:static;  
    width: 250px;
    height: 100px;
    z-index: 1000;    
}

.tire-result-discount-popup-content{
    width: 100%;
    text-align: left;
}

.tire-result-discount-popup-content-date{
    padding-bottom: 12px;
}

.tire-result-discount-popup-content-download{
   padding-left: 10px;
}

.tire-result-discount-popup-triangle {
    width: 40px;
    height: 40px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #242424;
    position: absolute;
    top: 30px;
    left: 248px;
    background-color: transparent;
    transform: rotate(180deg);
  }
  
  .tire-result-discount-popup-box {
    position: relative;  
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    padding: 13px 13px 13px 13px;        
    background-color: #242424;
    border-radius: 5px;
    border: 1px solid white;
  }