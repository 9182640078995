.tire-result-external-availability {
    background-color: white;
    border-top: 1px solid #F1F1F1;
}

.tire-result-external-availability-grid {
    grid-template-columns: 90px 275px 195px 230px 124px;
    width: 916px;
}

.tire-result-external-availability-head {
    background-color: #B2B2B2;
    height: 30px;
    margin: 4px 4px 4px 4px;
}

.tire-result-button-search {
    width: 90px;
    height: 30px;
    line-height: 30px;
    font-size: 0.625em;
}

.tire-result-external-availability-detail {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: white;
}

.tire-result-left-empty-column {
    width: 20px;
    min-width: 20px;
    margin-top: 10px;
}

.tire-result-right-column {
    text-align: left;
    margin-top: 10px;
    width: 950px;
}

.tire-result-right-column-no-margin {
    text-align: left;
    margin-top: 0px;
    width: 944px;
}

.tire-result-dispo-and-comm-section {
    display: table-row;
}

.tire-result-combo-bottom {
    border-bottom: 4px solid #E8E8E8;
}

.tire-result-nail-section {
    display: table-row;
    height: 20px;
}

.tire-result-grid-disp-soum-2-columns {
    grid-template-columns: 117px 117px;
    height: 100%;
}

.tire-result-span-2-columns {
    grid-column: span 2;
    display: table;
    height: 100%;
    margin: 0;
}

.tire-result-orange-header-table {
    width: 100%;
    height: 40px;
    color: white;
}

.tire-result-orange-header-column-1 {
    padding-left: 13px;
    width: 30px;
    font-size: 1.3em;
    text-align: left;
}

.tire-result-orange-header-column-2 {
    padding-left: 10px;
    width: 130px;
    text-align: left;
}

.tire-result-orange-header-column-3 {
    padding-left: 10px;
    text-align: left;
}

.tire-result-orange-header-column-4 {
    width: 230px;
    text-align: center;
}

.tire-result-yellow-header-table {
    width: 100%;
    height: 40px;
    color: white;
}

.tire-result-yellow-header-column-1 {
    padding-left: 13px;
    width: 30px;
    font-size: 1.3em;
    text-align: left;
}

.tire-result-yellow-header-column-2 {
    padding-left: 10px;
    width: 190px;
    text-align: left;
}

.tire-result-yellow-header-column-3 {
    padding-left: 10px;
    text-align: left;
}

.tire-result-yellow-header-column-4 {
    width: 300px;
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
}