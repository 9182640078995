.message_zone_sticky{
    position:sticky;
    position: -webkit-sticky;
    top: 30px; 
    z-index: 10000;   
    }

.message_zone_mainDiv {    
    width: 100%;
    height: 60px;
    text-align: center;    
}

.message_zone_topDiv {
    display: inline-block;
    width: 80%;
    height: 60px;
    border: 1px solid white;
    position: relative;
    background-color: #242424;
    color: white;
}

.message_zone_topDiv_columnOne {
    width: 100px;
    height: 60px;
    position: absolute;
    left: 10px;
}


.message_zone_topDiv_columnOne_innerColumnOne{
    position: absolute;
    left: 0;
    height: 60px;
    margin-right: 50px;
    transform: translate(0%, 33%);
}

.message_zone_topDiv_columnOne_innerColumnTwo{
    position: absolute;
    right: 10px;
    height: 60px;
    margin-left: 10px;
    transform: translate(0%, 30%);
}

.message_zone_topDiv_columnTwo {    
    height: 60px;
    width: 80%;
    line-height: 12px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.message_zone_topDiv_columnThree {
    width: 30px;
    height: 60px;          
    position: absolute;
    right: 0;
}

@media screen and (max-width: 1024px) {
    .message_zone_topDiv_columnOne_innerColumnTwo{
        visibility: hidden;
    }
}

.section-navigation-msg {
    height: 60px;
}

.section-navigation-msg .section-navigation-path-msg {
    font-size: 0.75em;
    line-height: 60px;
}

.section-navigation-msg .section-navigation-path-msg .path-msg {
    margin-right: 5px;
}

.section-navigation-msg .section-navigation-path-msg .path-msg:last-child {
    margin-right: 0;
}

.section-navigation-msg .section-navigation-path-msg .path-msg.bold .svg-arrow {
    fill: #ff5400;
}

.section-navigation-msg .section-navigation-path-msg .path-msg.current-msg {
    color: #242424;
    font-weight: 700;
}

.section-navigation-msg .section-navigation-path-msg .svg-arrow {
    fill: #8b8b8b;
    width: 3px;
    height: 6px;
}

