.S10-feature-products-button {
    width: 150px;
    height: 36px;
    display: inline-block;
    line-height: 36px;
    border-radius: 5px;
    background-color: rgba(255, 84, 0, 0);
    color: #ff5400;
    font-size: 0.75em;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.15s ease-in-out;
    border-width: thin;
    border-style: solid;
}

.S10-feature-products-star {
    padding-left: 0px !important;
    text-align: center;
    width: 100%;
}

.S10-feature-products-ul {
    display: inline-block !important;
    float: none !important;
    margin-right: 0px !important;
}