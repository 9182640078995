.s6-four-features-max-size-image {
    max-width: 500px;
}

.s6-four-features-brand-image {
    max-width: 400px;
    min-width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}