.checkbox-filter-2-section{
    grid-template-columns: 175px 25px;
    background-color: white;
}

.checkbox-filter-2-checkbox-section{
    grid-template-columns: 25px 150px;
    background-color: white;
}

.checkbox-filter-label{
    font-size: 1.1em !important;
    text-transform: none  !important;
    font-weight: 900 !important;
    letter-spacing: 0px !important;
    color: black;
    margin-bottom: 0px !important;
}

.checkbox-filter-checkbox{
    font-size: 0.9em !important;
    text-transform: none  !important;
    letter-spacing: 0px !important;
    color: black;
}

.checkbox-filter-checkbox-quantity{
    font-size: 0.9em !important;
    text-transform: none  !important;
    letter-spacing: 0px !important;
    color: gray;
}

.checkbox-filter-line-separator{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.checkbox-filter-arrow{
    fill: currentColor !important;
    color: #FF7200 !important;
    height: 18px !important;
    width: 24px !important;    
}

.checkbox-filter-arrow-up{
    fill: currentColor !important;
    color: #FF7200 !important;
    height: 18px !important;
    width: 24px !important;    
}

.checkbox-filter-so-accordion-wrapper {
    padding-top: 0rem;
    overflow: visible;
    border-bottom: 1px solid #eaeaea;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 820px;
}

.checkbox-filter-so-tab {
    position: relative;
    width: 100%;
    border-top: 1px solid #eaeaea;
    overflow: hidden;
    padding-left: 0px;
    padding-right: 20px;
}

.checkbox-filter-so-tab-no-padding-top{
    border-top: 0px !important;
}

.checkbox-filter-so-tab label {
    position: relative;
    display: block;
    padding: 0 0 0 0;
    margin-bottom: 5px;
    margin-top: 5px;
    line-height: normal;
    cursor: pointer;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 12px;
}

.checkbox-filter-so-tab input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.checkbox-filter-so-tab-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.checkbox-filter-so-tab input:checked ~ .checkbox-filter-so-tab-content {
    max-height: 1000px;
    transition: max-height 0.5s ease-in-out;
}

.checkbox-filter-so-tab label::after {
    position: absolute;
    right: 0;
    top: -5px;
    font-size: 1.5em;
    color: #959595;
    display: block;
    -webkit-transition: all 0.50s ease;
    -moz-transition: all 0.50s ease;
    -o-transition: all 0.50s ease;
    transition: all 0.50s ease;
}
