.converter-page-gray-line{
    background-color: #E8E8E8;
    height: 1px;
    min-height: 1px;
    width: 630px;    
}

.converter-page-calc-button{
    width: 140px;
    height: 30px;
    line-height: 30px;
}

.converter-page-diameter{
    margin-bottom: 12px;
    color: #242424;
    font-size: 0.9375em;
    font-weight: 700;
}

.converter-page-rl-label{
    display: block;
    margin-bottom: 12px;
    color: #242424;
    font-size: 0.9375em;
    font-weight: 700;
    font-family: "Lato", sans-serif;
}

.converter-page-dim-result-grid-15-columns{
    grid-template-columns: 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px 55px;
}

.converter-page-dim-result-grid-4-columns{
    grid-template-columns: 150px 150px 150px 230px;
}

.converter-page-dim-result-grid-6-columns{
    grid-template-columns: 90px 15px 90px 15px 90px 200px;
}

.converter-page-button{
    border-radius: 0px;
    width: 50px;
    height: 30px;    
    line-height: 30px;
    font-size: 0.8em;
}

.converter-page-result-top{ 
    margin-top: 15px;
    background-color:#242424;
    color:white;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 30px;
    height: 30px;
}

.converter-page-result {         
    color:black;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-size: 0.75em;
    line-height: 20px;
    height: 20px;
}

.converter-page-result-odd { 
    background-color:white;
}

.converter-page-result-even { 
    background-color:#F0F0F0;
}

.converter-page-force-top{
    top: 12px !important;
    }
