.tabveh-width-tire-pixel {
    width: 180px;
}

.tabveh-grid-1-column {
    grid-template-columns: 100%;
    background-color: #F1F1F1;
}

.tabveh-grid-2-columns{
    grid-template-columns: 200px 200px;
    background-color: #F1F1F1;
}

.tabveh-grid-5-columns{
    grid-template-columns: 150px 170px 200px 200px 400px;
    background-color: #F1F1F1;
}

.tabveh-grid-5-columns_2{
    grid-template-columns: 200px 200px 200px 200px 200px;
    background-color: #F1F1F1;
}

.tabveh-grid-2-columns_3 {
    grid-template-columns: 400px 250px;
    background-color: #F1F1F1;
}


.tabveh-grid-2-columns_2 {
    grid-template-columns: 50% 50%;
    background-color: #F1F1F1;
}

.tabveh-width90 {
    width: 90%;
}

.tabveh-button-erase {
    width: 90%;
    height: 40px;
    line-height: 36px;
    font-size: 0.625em;
}

.tabveh-button-search {
    width: 90%;
    height: 40px;
    line-height: 40px;
    font-size: 0.625em;
}

.tabveh-label-font-bold {
    font-size: 15px;
    font-weight: 700;
    color: #242424;
    font-family: "Lato", sans-serif;
}